<template>
    <div class="page-container">
        <br />
        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" />
        <br /><br />
        <div class="login md-alignment-top-center md-layout">
            <md-card md-with-hover class="md-layout-item md-size-30">
                <md-card-header>
                    <h1 class="md-title">Reset de mot de passe</h1>
                </md-card-header>
                <form @submit.prevent="submit">

                    <md-card-content>
                        <md-vuelidated>
                            <label>Votre email</label>
                            <md-input v-model.trim="form.email" type="email"></md-input>
                            <md-vuelidated-msg constraint="required">Merci de saisir votre email.</md-vuelidated-msg>
                            <md-vuelidated-msg constraint="email">L'email saisi n'est pas valide</md-vuelidated-msg>
                            <span class="md-helper-text">Si votre compte existe, vous recevrez un email contenant un lien permettant de définir un nouveau mot de passe</span>
                        </md-vuelidated>
                    </md-card-content>

                    <md-card-actions>
                        <br />
                        <br /><br /><br /><br/><br /><br /><br />
                        <md-button class="md-raised" type="submit">Recevoir un lien de reset de mot de passe</md-button>
                    </md-card-actions>
                </form>
                
            </md-card>            
        </div>
    </div>
</template>
      

<script>
import {required, email} from 'vuelidate/lib/validators'

import {lostPassword} from '@/api/user'

export default {
  name: 'Lost',
  data(){
      return {
          loader: null,
          form:{
              email:''
          }
      }
  },
  validations:{
      form:{
          email:{
              required,
              email
          }
      }
  },
  methods:{
      submit(){
          this.$v.$touch();
          if(!this.$v.$invalid){
              // submission
              this.loader=this.$loading.show();
              lostPassword(this.form)
              .then((data) => {
                  this.loader.hide();
                  this.$alert(data.message,'Success','success');
              }).catch((e) => {
                  this.loader.hide();
                  console.log(e);
                  this.$alert(e.response.data.error,'Error','error');
              })
          }
      }
  }
  
}
</script>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
img.logo{
    max-height: 50px;
}
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>